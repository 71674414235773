import React from "react";
import { PageLayout } from "../components/page-layout";

export const LicensePage = () => {

    return (
        <PageLayout>
            <div id="license" className="flex flex-col object-center sm:w-2/3 md:w-1/3 h-fit">
                <h1 className="mx-auto text-3xl text-blue-900 font-sans font-semibold tracking-wide mb-5">RUNABOUT</h1>
                <img src="imgs/RunaboutNew.png" alt="Runabout Logo" className="object-center mx-auto h-32 w-32 mb-6" />

                <h1 className="text-2xl mx-auto my-8">
                    End User License Agreement of Runabout (“EULA”)
                </h1>
                <p className="mb-3">
                    “Developer” means the author of Runabout: Ethan Bond
                </p>
                <p className="mb-3">
                    “JetBrains” means JetBrains s.r.o. with its registered office at Na Hřebenech II 1718/10, Prague, 14000,
                    Czech Republic,
                    registered with the Commercial Register kept by the Municipal Court of Prague, Section C, file 86211,
                    ID.Nr.: 265 02
                    275.
                </p>
                <p className="mb-3">
                    “JetBrains Affiliate” means the subsidiary and/or any associated companies of JetBrains.
                </p>
                <p className="mb-3">
                    “JetBrains Marketplace” means any platform operated by JetBrains or a JetBrains Affiliate on which
                    JetBrains or a
                    JetBrains Affiliate markets Plugins for JetBrains Products, including the website
                    https://plugins.jetbrains.com and/or
                    any other website or other platform, whether named JetBrains Marketplace, JetBrains Plugins Repository,
                    or otherwise.
                </p>
                <p className="mb-3">
                    “JetBrains Product” means any software program or service made available by JetBrains.
                </p>
                <p className="mb-3">
                    “Plugin” means the Plugin for JetBrains Product that Developer makes available under this EULA.
                </p>
                <p className="mb-3">
                    “Plugin Information” means the following information and materials: (a) JetBrains Marketplace Plugin
                    title, tags /
                    category, name(s) of Developer(s), product description, icon, logo or banner images, and any other
                    information related
                    to Plugins; (b) the metadata, graphics, artwork, images, trademarks, trade names, logos and other
                    descriptive or
                    identifying information and materials associated with Developer or appears in connection with Plugin;
                    and (c) in the
                    case of cloud hosted Plugins, an XML/JSON descriptor of Plugin.
                </p>
                <p className="mb-3">
                    “Plugin Users” means users that are able to access and use Plugin concurrently.
                </p>
                <p className="mb-3">
                    “You” means an individual or an entity concluding this EULA.
                </p>
                <p className="mb-3">
                    This EULA governs the terms of use of Plugin made available to You via JetBrains Marketplace. This EULA
                    is entered into
                    between You and Developer.
                </p>
                <p className="mb-3">
                    If Plugin is a paid Plugin, you must ensure that the maximum number of Plugin Users does not exceed the
                    number of Plugin
                    Users for which you have purchased Plugin.
                </p>
                <p className="mb-3">
                    You are authorized to use Plugin in accordance with its documentation provided by Developer and for the
                    period of time
                    specified by Developer.
                </p>
                <p className="mb-3">
                    You may not modify, reverse-engineer, decompile, or disassemble Plugin in whole or in part, or create
                    any derivative
                    works from Plugin, or sublicense any rights in Plugin, unless otherwise expressly authorized in writing
                    by Developer.
                </p>
                <p className="mb-3">
                    Plugin is protected by copyright and other intellectual property laws and treaties. Developer or its
                    licensors own all
                    title, copyright and other intellectual property rights in Plugin.
                </p>
                <p className="mb-3">
                    ALL PLUGINS ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES. USE OF
                    PLUGINS IS AT YOUR OWN
                    RISK. DEVELOPER MAKES NO WARRANTY AS TO PLUGIN’S USE OR PERFORMANCE. TO THE MAXIMUM EXTENT PERMITTED BY
                    APPLICABLE LAW,
                    DEVELOPER DISCLAIMS ALL OTHER WARRANTIES AND CONDITIONS, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                    LIMITED TO,
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT,
                    WITH REGARD TO
                    PLUGINS, AND THE PROVISION OF OR FAILURE TO PROVIDE SUPPORT SERVICES. DEVELOPER DOES NOT WARRANT THAT
                    PLUGINS ARE
                    ACCURATE, RELIABLE, OR CORRECT; THAT PLUGIN MEETS YOUR REQUIREMENTS; THAT PLUGINS WILL BE AVAILABLE AT
                    ANY PARTICULAR
                    TIME OR LOCATION, UNINTERRUPTED, OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT
                    PLUGINS ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS.
                </p>
                <p className="mb-3">
                    IN NO EVENT WILL DEVELOPER BE LIABLE FOR ANY DIRECT OR INDIRECT COSTS, LOSSES, OR DAMAGES ASSOCIATED
                    WITH THE USE OF
                    DEVELOPER’S PLUGINS.
                </p>
                <p className="mb-3">
                    DEVELOPER SHALL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, AND
                    IN NO EVENT WILL
                    DEVELOPER’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE USE OF PLUGIN EXCEED
                    THE FEES WHICH
                    YOU PAID VIA JETBRAINS PLUGIN MARKETPLACE SERVICE FOR PLUGINS IN THE THREE-MONTH PERIOD PRECEDING THE
                    CLAIM. THIS
                    LIMITATION WILL APPLY EVEN IF DEVELOPER HAS BEEN ADVISED OF THE POSSIBILITY OF THE LIABILITY EXCEEDING
                    THE AMOUNT AND
                    NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. JETBRAINS’ LIABILITY IS EXCLUDED
                    IN ITS ENTIRETY
                    AS JETBRAINS IS NOT A PARTY TO THE CONTRACTUAL RELATIONSHIP BETWEEN DEVELOPER AND CUSTOMER.
                </p>
            </div>
        </PageLayout>
    );

};