import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getUserInfo = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/info`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, 20000);

  return {
    data: data || null,
    error,
  };
};

export const clearUserInfo = () => {
  localStorage.removeItem(`${apiServerUrl}/info`);
};

export const getUserUsage = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/usage`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, 20000);

  return {
    data: data || null,
    error,
  };
};

export const getNewToken = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/newtoken`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, null);

  return {
    data: data || null,
    error,
  };
};

export const fetchOrg = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/organization/fetch`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, null);

  return {
    data: data || null,
    error,
  };
};

export const createOrg = async (accessToken, name) => {
  const config = {
    url: `${apiServerUrl}/organization/create`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      name: name
    }
  };

  const { data, error } = await callExternalApi({ config }, null);

  clearUserInfo();

  return {
    data: data || null,
    error,
  };
};


export const updateOrg = async (accessToken, id, name) => {
  const config = {
    url: `${apiServerUrl}/organization/update/${id}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      name: name
    }
  };

  const { data, error } = await callExternalApi({ config }, null);

  clearUserInfo();

  return {
    data: data || null,
    error,
  };
};

export const addUserToOrg = async (accessToken, id, userString) => {
  const config = {
    url: `${apiServerUrl}/organization/add/${id}/${userString}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }, null);

  clearUserInfo();

  return {
    data: data || null,
    error,
  };
}


export const removeUserFromOrg = async (accessToken, id, userName) => {
  const config = {
    url: `${apiServerUrl}/organization/remove/${id}/${userName}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }, null);

  clearUserInfo();

  return {
    data: data || null,
    error,
  };
}


export const orgAction = async (accessToken, id, action) => {
  const config = {
    url: `${apiServerUrl}/organization/${action}/${id}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, null);

  clearUserInfo();

  return {
    data: data || null,
    error,
  };
}

export const fetchKeys = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/fetch/access_tokens`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, null);

  return {
    data: data || null,
    error,
  };
};

export const createKey = async (accessToken, name) => {
  const config = {
    url: `${apiServerUrl}/create/access_token`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      name: name
    }
  };

  const { data, error } = await callExternalApi({ config }, null);

  return {
    data: data || null,
    error,
  };
};

export const disableKey = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/disable/access_token/${id}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }, null);

  return {
    data: data || null,
    error,
  };
}

export const fetchScenarioEvents = async (accessToken, limit, offset) => {
  const config = {
    url: `${apiServerUrl}/catalog/fetch/scenario_events?limit=${limit}&offset=${offset}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }, null);

  return {
    data: data || null,
    error,
  };
}