import axios from "axios";

export const callExternalApi = async (options, delta) => {
  try {

    let entry = localStorage.getItem(options.config.url);

    if (entry) {
      entry = JSON.parse(entry);
      if (Date.now() - entry.timestamp < delta) {
        console.log('Returning cached data');
        return {
          data: entry.data,
          error: null,
        }
      }
    }

    const response = await axios(options.config);

    // console.log(response);
    let status = response.status;
    const { data } = response;


    let error = null;
    if (status !== 200 && data != null && data.message !== null) {
      error = {
        message: data.message,
      };
    }

    if (delta !== null) {
      localStorage.setItem(options.config.url, JSON.stringify({ data, timestamp: Date.now() }));
    }

    return {
      data,
      error: error,
    };
  } catch (error) {
    // console.log(error.response.data);
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};
