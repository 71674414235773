import React from "react";
import { NavBar } from "./navigation/desktop/nav-bar";
import { PageFooter } from "./page-footer";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

const items = [
  {
    name: 'Dashboard',
    url: '/dashboard',
  },
  {
    name: 'Settings',
    url: '/settings',
  },
];

export const PageLayout = ({ sidebar = false, children }) => {
  const location = useLocation();

  return (
    <div className="min-h-full">
      <NavBar />
      <div className="inline flex ">
        {sidebar && <Sidebar>
          <Menu>
            {items.map((menuItem) => (
              <MenuItem
                className={location.pathname === menuItem.url ? 'bg-gray-200' : ''}
                key={menuItem.name}
                component={<Link to={menuItem.url} />}
              >
                {menuItem.name}
              </MenuItem>
            ))}
          </Menu>
        </Sidebar>}
        <div className={`flex flex-grow ${sidebar ? 'ml-16' : 'justify-center'} sm:mt-6 md:mt-10 lg:mt-12 min-h-[90vh]`}>
          {children}
        </div>
      </div>
      <PageFooter />
    </div>
  );
};
