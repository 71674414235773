import React, { useState, useRef, useEffect } from 'react';

const MobileNavbarPopup = ({ isOpen, onClose, children }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(isOpen);

    // Close the popup when clicking outside of it
    const popupRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
                onClose();
            }
        }

        if (isPopupOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupOpen, onClose]);

    // Handle changes in isOpen prop
    useEffect(() => {
        setIsPopupOpen(isOpen);
    }, [isOpen]);

    return (
        <div className={`fixed inset-0 z-50 ${isPopupOpen ? 'block' : 'hidden'}`}>
            <div className="fixed inset-0 bg-gray-800 opacity-50"
                onClick={() => {
                    setIsPopupOpen(false);
                    onClose();
                }}
            />
            <div ref={popupRef}
                className="fixed inset-x-0 top-0 bg-white p-4 shadow-md transform translate-y-0 transition-transform duration-300 ease-in-out">
                {children}
            </div>
        </div>
    );
}

export default MobileNavbarPopup;
