import React from "react";
import { NavLink } from "react-router-dom";
import  logo from "./RunaboutNew.png";

export const NavBarBrand = () => {
  return (
    <NavLink to="/">
      <div className="flex items-center">
        <img
          className="float-left md:float-none"
          src={logo}
          alt="Runabout Logo"
          width="60"
          height="36"
        />
        <span className="text-3xl text-blue-900 font-sans font-semibold tracking-wide ml-2 hidden md:inline">RUNABOUT</span>
      </div>
    </NavLink>
  );
};
