import { NavLink } from "react-router-dom";
import React from "react";
import { orgAction } from "../services/user.service";
import { useAuth0 } from "@auth0/auth0-react";

export const SettingsOrganizationInfo = ({ org, org_status }) => {

    const { getAccessTokenSilently } = useAuth0();

    const doOrgAction = async (action, id) => {
        const accessToken = await getAccessTokenSilently();
        await orgAction(accessToken, id, action);
        window.location.reload();
    }

    const handleOrgExists = (org, org_status) => {
        switch (org_status) {
            case "admin":
                return (
                    <>
                        <label className="block text-gray-600 text-sm font-medium mb-6">Org: {org.name}</label>
                        <div>
                            <NavLink to="/organization" >
                                <span className="bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 mb-6 rounded">
                                    Manage org
                                </span>
                            </NavLink>
                        </div>
                    </>
                );

            case "pending":
                return (
                    <>
                        <label className="block text-gray-600 text-sm font-medium mb-6">Invitation from org: {org.name}</label>
                        <div>
                            <button onClick={() => doOrgAction("accept", org.id)} className="bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 mb-6 rounded block">
                                Accept
                            </button>
                            <button onClick={() => doOrgAction("decline", org.id)} className="bg-red-500 hover:bg-red-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 mb-6 rounded block">
                                Decline
                            </button>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <label className="block text-gray-600 text-sm font-medium mb-6">Org: {org.name}</label>
                    </>
                );
        }
    }

    let org_element = null;
    if (org == null || org.id == null) {
        org_element = (
            <>
                <label className="block text-gray-600 text-sm font-medium mb-6">No org connected.</label>
                <NavLink to="/organization" >
                    <span className="bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 rounded">
                        Create an org
                    </span>
                </NavLink>
            </>
        );
    } else {
        org_element = handleOrgExists(org, org_status);
    }
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            <div className="md:flex">
                <div className="p-8">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Organization Information</div>
                    <div className="mt-4">
                        {org_element}
                    </div>
                </div>
            </div>
        </div>
    )
};