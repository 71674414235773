import React, { useState, useEffect } from 'react';
import { clearUserInfo, getNewToken } from '../services/user.service';

export const ApiKeyModal = ({ show, closeModalCallback, getAccessTokenSilently }) => {

    const [isOpen, setIsOpen] = useState(show);
    const [apiKey, setApiKey] = useState("");

    useEffect(() => {
        setIsOpen(show);
    }, [show]);


    useEffect(() => {
        setIsOpen(show);
        let isMounted = true;

        if (show === true) {
            clearUserInfo();
            const getKey = async () => {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getNewToken(accessToken);

                if (!isMounted) {
                    return;
                }

                if (data) {
                    setApiKey(data.new_api_key);
                }

                if (error) {
                    // TODO handle error
                    // console.log(error);
                }
            };

            getKey();
        } else {
            setApiKey("");
        }

        return () => {
            isMounted = false;
        };
    }, [show, getAccessTokenSilently]);


    return (
        <div className={"fixed inset-0 z-10 " + (isOpen ? 'block' : 'hidden')}>
            <div className="modal-overlay bg-opacity-50 bg-black"></div>
            <div className="modal-container bg-white max-w-fit mx-auto mt-24 rounded-lg shadow-lg">
                <div className="modal-content p-4">
                    {/* Your modal content goes here */}
                    <h2 className="text-xl font-semibold mb-2">New Api Key:</h2>
                    <p className="text-gray-600 text-sm mb-4">Copy this now and keep it safe. <br /> You will need to get a new API key if lost.</p>
                    <p className="p-4 bg-gray-200 rounded">{apiKey.trim()}</p>
                    <button className="modal-close-button top-2 right-2 p-2 text-gray-600" onClick={closeModalCallback}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};
