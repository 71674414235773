import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <button className="ml-3 border border-blue-700 text-black hover:bg-blue-700 hover:text-white font-semibold py-2 px-4 rounded-lg" onClick={handleLogout}>
      Log Out
    </button>
  );
};
