import { PageLayout } from "../components/page-layout";
import React from 'react';

const ShareScenarioPage = () => {
    return (
        <PageLayout sidebar>
            <div id="content" className="flex flex-col object-center text-center sm:w-2/3 md:w-1/3 h-fit pb-20">
                <h1 className="text-2xl mx-auto my-8">
                    Open Scenario from Link
                </h1>
                <ul className="text-left list-disc pl-6 pt-2">
                    <li>
                        Copy the current URL to your clipboard.
                    </li>
                    <li>
                        In Intellij, open the Runabout scenario panel (right side).
                    </li>
                    <li>
                        Click the "Launch" button at the bottom.
                    </li>
                </ul>
            </div>
        </PageLayout>
    );
}

export default ShareScenarioPage;