import React from "react";
import { PageLayout } from "../components/page-layout";
import { SubscribeHeader } from "../components/subscribe-header";
import { SignupButton } from "../components/buttons/signup-button";

export const LandingPage = () => (
  <>
    <SubscribeHeader />
    <PageLayout>
      <div className="flex-row w-full object-center justify-center">
        <div id="content" className="w-full">
          <div className="text-center flex flex-col justify-center object-center mx-auto lg:w-1/3 h-fit pb-20 block">
            <h1 className="text-5xl font-bold text-gray-800 mb-4">Spend less time debugging code</h1>
            <p className="text-gray-600 text-lg mb-8">How? With Runabout's replay debugger. Watch the video below to see a developer solve a production bug in 1 minute using Runabout.</p>  
            <div className="flex flex-col md:flex-row md:space-x-4 md:justify-center items-center mx-auto">
              <SignupButton htmlClass={"cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full mb-4 md:mb-0"} />
              <a href="#ytplayer" className="cursor-pointer bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold px-6 py-3 rounded-full">
                <span className="pl-0 pr-2 material-icons align-middle text-md">
                  play_circle
                </span>
                Watch Demo
              </a>
            </div>
          </div>

          <div id="ytplayer" className="relative w-full mt-4 pt-4 text-center md:w-2/3 mx-auto">
            <div className="flex justify-center items-center h-0 pb-[56.25%]">
              <iframe className="absolute inset-0 w-full h-full" src="https://player.vimeo.com/video/940682103?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1026" height="644" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="RunaboutTrimmedDemo"></iframe>
            </div>
          </div>
        </div>

        <div className="relative w-full pt-12 text-center min-h-[40vh] text-center bg-gradient-to-b from-white via-blue-50 to-slate-50">
          <div className="flex flex-col md:flex-row items-start justify-center mx-auto">
            <div className="flex-shrink-0 md:w-1/3 p-4 pt-8 align-middle">
              <div className="overflow-hidden">
                <h2 className="text-3xl font-bold">What <i>exactly</i> is Runabout?</h2>
                <p className="text-gray-600 text-xl text-left pt-3 mx-auto">
                  Runabout is an IDE Plugin and logging framework. 
                </p>
                <p className="text-gray-600 text-xl text-left pt-3 mx-auto">
                  Add the logging to your server, and it saves off the state of the program as it runs and when errors occur.
                </p>
                <p className="text-gray-600 text-xl text-left pt-3 mx-auto">
                  Then use the plugin to recreate and rerun this state on your own computer to troubleshoot faster! 
                </p>
              </div>
            </div>
            <div className="flex-shrink-0 md:w-1/3 p-4">
              <div className="overflow-hidden">
                <iframe title="Runabout Jetbrains Card" width="384px" height="319px" src="https://plugins.jetbrains.com/embeddable/card/22630"></iframe>              
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full pt-12 text-center min-h-[35vh] text-center bg-gradient-to-b from-blue-50 to-white">
          <div className="flex flex-col md:flex-row md:w-7/12 items-center justify-center mx-auto">
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="m422-232 207-248H469l29-227-185 267h139l-30 208ZM320-80l40-280H160l360-520h80l-40 320h240L400-80h-80Zm151-390Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Get started in 5 minutes
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                    Installing the Runabout plugin and creating a Runabout account are both 100% free.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-2/3 md:w-1/2 p-4">
              <div>
                <ol className="text-xl text-left leading-loose font-semibold">
                  <li>
                    1. Install the plugin (1 minute)
                  </li>
                  <li>
                    2. Run your first method (&gt; 2 minutes)
                  </li>
                  <li>
                    3. Create an account and sync scenarios w/ the cloud (2 minutes)
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full pt-12 text-center min-h-[35vh] text-center bg-gradient-to-b from-white via-blue-100 to-blue-200">
          <div className="flex flex-col md:flex-row md:w-3/4 items-center justify-center mx-auto">
            <div className="xl:w-5/12 md:w-1/2 p-4">
              <div className="bg-white rounded-lg p-6 rounded-2xl shadow-md">
               <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="M480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440h80q0 117 81.5 198.5T480-160q117 0 198.5-81.5T760-440q0-117-81.5-198.5T480-720h-6l62 62-56 58-160-160 160-160 56 58-62 62h6q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-440q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-80Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Replay directly from logs
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                    Use the runabout-java library to create a formatted JSON object for logging that will convey the state of all parameters of a method. Use this data to replay the method in your IDE the same way it executed in production.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-5/12 md:w-1/2 p-4">
              <div className="bg-white rounded-lg p-6 rounded-2xl shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Save a debug session and replay it later 
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                    While debugging your program, you can capture the state of a stack frame and rerun the same method with the same parameters in the future. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="relative w-full pt-12 text-center min-h-[35vh] text-center bg-gradient-to-b from-blue-200 to-blue-300">
          <div className="flex flex-col md:flex-row md:w-3/4 items-center justify-center mx-auto">
            <div className="xl:w-5/12 md:w-1/2 p-4">
              <div className="bg-white rounded-lg p-6 rounded-2xl shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="M480-600 340-740l140-140 140 140-140 140ZM40-160v-160q0-34 23.5-57t56.5-23h131q20 0 38 10t29 27q29 39 71.5 61t90.5 22q49 0 91.5-22t70.5-61q13-17 30.5-27t36.5-10h131q34 0 57 23t23 57v160H640v-91q-35 25-75.5 38T480-200q-43 0-84-13.5T320-252v92H40Zm120-280q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T280-560q0 50-34.5 85T160-440Zm640 0q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T920-560q0 50-34.5 85T800-440Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Share scenarios with your team
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                    With Runabout enterprise, you can see all the scenarios created by your teammates in your IDE and run them as needed.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-5/12 md:w-1/2 p-4">
              <div className="bg-white rounded-lg p-6 rounded-2xl shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="M419-80q-28 0-52.5-12T325-126L107-403l19-20q20-21 48-25t52 11l74 45v-328q0-17 11.5-28.5T340-760q17 0 29 11.5t12 28.5v472l-97-60 104 133q6 7 14 11t17 4h221q33 0 56.5-23.5T720-240v-160q0-17-11.5-28.5T680-440H461v-80h219q50 0 85 35t35 85v160q0 66-47 113T640-80H419ZM167-620q-13-22-20-47.5t-7-52.5q0-83 58.5-141.5T340-920q83 0 141.5 58.5T540-720q0 27-7 52.5T513-620l-69-40q8-14 12-28.5t4-31.5q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 17 4 31.5t12 28.5l-69 40Zm335 280Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Invoke any method in your code
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                    Runabout is a REPL-like tool, this means you can use it to invoke any method in your project (even private methods!). You can replay saved scenarios and create new scenarios by hand, building the perfect java objects to test your code.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-full pt-12 text-center min-h-[35vh] text-center bg-blue-300">
          <div className="flex flex-col md:flex-row md:w-3/4 items-center justify-center mx-auto">
            <div className="xl:w-5/12 md:w-1/2 p-4">
              <div className="bg-white rounded-lg p-6 rounded-2xl shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Use your existing logging infrastructure 
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                  The runabout-java library is effectively a fancy `toString` method. Its purpose is to convert your runtime objects into a standard format for the runabout plugin to read. Use your existing logging provider and storage to store + access the data. This means your runtime data never has to leave your servers!
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-5/12 md:w-1/2 p-4">
              <div className="bg-white rounded-lg p-6 rounded-2xl shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" className="mx-auto" viewBox="0 -960 960 960" width="40"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-80q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-80q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Z"/></svg>
                <h3 className="text-2xl font-bold mx-auto mb-6">
                  Log only what you need
                </h3>
                <div className="text-xl text-left leading-loose">
                  <p>
                  The runabout-java library is only invoked when you explicitly call into it in methods you want to be able to rerun. If any variables may contain sensitive information, you can simply omit them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </PageLayout>
  </>
);
