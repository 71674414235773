import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = ({ cssClass }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const css = cssClass ? cssClass : "ml-3 border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white font-semibold py-2 px-4 rounded-lg";

  return (
    <button className={css} onClick={handleLogin}>
      Log In
    </button>
  );
};
