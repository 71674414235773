import React from "react";

export const SettingsUserInfo = ({ user, plan }) => {

    if (user === null) {
        return (<div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            Loading...
        </div>);
    }

    return (<div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
        <div className="md:flex">
            <div className="p-8">
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">User Information</div>
                <div className="mt-4">
                    <label className="block text-gray-600 text-sm font-medium">Name:</label>
                    <input type="text" className="form-input mt-1 block w-full border-gray-300 rounded-md" value={user.name} disabled />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-600 text-sm font-medium">Email:</label>
                    <input type="text" className="form-input mt-1 block w-full border-gray-300 rounded-md" value={user.email} disabled />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-600 text-sm font-medium">Plan:</label>
                    <input type="text" className="form-input mt-1 block w-full border-gray-300 rounded-md" value={plan == null ? "Loading..." : plan.toUpperCase()} disabled />
                </div>
            </div>
        </div>
    </div>
    )
};