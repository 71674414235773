import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const SignupButton = ({ htmlClass = null }) => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  let className = htmlClass === null ? "bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md" : htmlClass;

  return (
    <button className={className} onClick={handleSignUp}>
      Sign Up
    </button>
  );
};
