import React from "react";


export const SettingsSubscriptionInfo = ({ plan, org, errorCallback }) => {

    const teamClickValidation = (e) => {
        if (org.id == null) {
            e.preventDefault();
            errorCallback("Must create an organization first to subscribe for a team.");
        }
    }

    return (<div className="max-w-[calc(50%-8px)] mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl" >
        <div className="md:flex">
            <div className="p-8 ">
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Subscription Information</div>
                <div className="mt-4">
                    <label className="block text-gray-600 text-sm font-medium mb-6">Manage your Subscription:</label>
                    {
                        plan == null ? "Loading..." :
                            plan === "pro" || (plan === "team_admin" && org.user_allowance > 0) ?
                                (<>
                                    <a href={process.env.REACT_APP_STRIPE_PORTAL_LINK} target="_blank" rel="noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 rounded">
                                        Go to Billing Portal
                                    </a>
                                </>) :
                                plan === "team" ?
                                    (<>
                                        <p className="text-gray-600 text-sm font-medium mb-3">Subscription managed by org</p>
                                    </>) :
                                    (<>
                                        <label className="block text-gray-600 text-sm font-medium mb-6"></label>
                                        <a href={process.env.REACT_APP_STRIPE_CHECKOUT_LINK} target="_blank" rel="noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 rounded">
                                            Upgrade to Pro
                                        </a>
                                        <label className="block text-gray-600 text-sm font-medium my-3">Or</label>
                                        <a onClick={teamClickValidation} href={process.env.REACT_APP_STRIPE_TEAM_CHECKOUT_LINK} target="_blank" rel="noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 rounded">
                                            Subscribe for your org
                                        </a>
                                    </>)
                    }
                </div>
            </div>
        </div>
    </div>
    );
}