import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { getUserInfo } from "../services/user.service";
import { SettingsUserInfo } from "../components/settings-user-info";
import { SettingsApiInfo } from "../components/settings-api-info";
import { ApiKeyModal } from "../components/api-key-modal";
import { SettingsSubscriptionInfo } from "../components/settings-subscription-info";
import { SettingsOrganizationInfo } from "../components/settings-organization-info";
import { WarningBadge } from "../components/warning-badge";

export const SettingsPage = () => {

  const [showApiModal, setShowApiModal] = useState(false);
  const [apiInfo, setApiInfo] = useState({ plan: "free", org: null, org_status: null });
  const [issueContent, setIssueContent] = useState(null);

  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;

    const getInfo = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getUserInfo(accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setApiInfo(data);
      }

      if (error) {
        // TODO handle error
        setApiInfo(JSON.stringify(error, null, 2));
      }
    };

    getInfo();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, showApiModal]);

  if (!user) {
    return null;
  }

  return (
    <PageLayout sidebar>
      <div className="min-h-screen ">
        <h1 id="page-title" className="bold text-4xl mb-6 mt-0">
          Settings
        </h1>
        <WarningBadge content={issueContent} visible={issueContent != null} onClose={() => setIssueContent(null)} />
        <div className="flex space-x-4">
          <div className="grid md:grid-rows-2 md:grid-cols-2 gap-4 mb-8">
            <SettingsUserInfo user={user} plan={apiInfo.plan} />
            <SettingsApiInfo apiInfo={apiInfo} newTokenCallback={() => setShowApiModal(true)} />
            <SettingsSubscriptionInfo plan={apiInfo.plan} org={apiInfo.org} errorCallback={(c) => setIssueContent(c)} />
            <SettingsOrganizationInfo org={apiInfo.org} org_status={apiInfo.user_org_status} />
          </div>
          <ApiKeyModal show={showApiModal} closeModalCallback={() => setShowApiModal(false)} getAccessTokenSilently={getAccessTokenSilently} />
        </div>
      </div>
    </PageLayout>
  );
};
