import { PageLayout } from "../components/page-layout";
import React, { useState, useEffect } from 'react';
import { addUserToOrg, createOrg, fetchOrg, removeUserFromOrg } from "../services/user.service";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import { WarningBadge } from "../components/warning-badge";

const OrganizationPage = () => {
    const [orgId, setOrgId] = useState(null);
    const [orgName, setOrgName] = useState('');
    const [orgSeats, setOrgSeats] = useState(0); // Replace with your actual seats count
    const [issueContent, setIssueContent] = useState(null);
    const [users, setUsers] = useState([]);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [dataLock, setDataLock] = useState(false);

    const { getAccessTokenSilently } = useAuth0();


    useEffect(() => {
        let isMounted = true;

        const getInfo = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await fetchOrg(accessToken);

            console.log(data, error);

            if (!isMounted) {
                return;
            }

            if (data) {
                setOrgId(data.org.id);
                setOrgName(data.org.name);
                setOrgSeats(data.org.user_allowance);
                setUsers(data.users.map((user) => ({ email: user.email, status: user.status })));
            }

            if (error) {
            }
        };

        getInfo();

        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently]);

    const handleAddUser = async () => {
        if (newUserEmail && orgId != null && !dataLock) {
            setDataLock(true);
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await addUserToOrg(accessToken, orgId, encodeURIComponent(newUserEmail));
            if (error == null) {
                if (data.added_users.length > 0) {
                    let newUsers = [...users];
                    Array.prototype.push.apply(newUsers, data.added_users.map(e =>  { 
                        return {email: e, status: 'pending' }
                    }));
                    setUsers(newUsers);
                }

                let warningMsg = ""

                if (data.non_users.length > 0) {
                    warningMsg += "The following emails do not have Runabout accounts yet: [" + data.non_users.join(", ") + "]. ";
                } 
              
                if (data.claimed_users.length > 0) {
                    warningMsg += "The following emails belong to users that already belong to an Organization: [" + data.claimed_users.join(", ") + "]. ";
                }                
                
                if (warningMsg.length > 0) {
                    setIssueContent(warningMsg);
                }
            } else {
                setIssueContent(error.message);
            }
            setDataLock(false);
        }
    }

    const handleRemoveUser = async (idx) => {
        if (orgId !== null && idx !== null && !dataLock) {
            setDataLock(true);
            const accessToken = await getAccessTokenSilently();
            const user = users.at(idx);
            const { data, error } = await removeUserFromOrg(accessToken, orgId, encodeURIComponent(user.email));
            console.log(data);
            if (error == null) {
                const newUsers = [...users];
                newUsers.splice(idx, 1);
                setUsers(newUsers);
            } else {
                setIssueContent(error.message);
            }
            setDataLock(false);
        }
    }

    const handleCreateOrg = async () => {
        if (orgId == null && orgName) {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await createOrg(accessToken, orgName);
            // console.log(data);
            if (error == null) {
                window.location = "/settings";
            } else {
                setIssueContent(error.message);
            }
            console.log(data, error);
        }
    }

    return (
        <PageLayout sidebar>
            <div className="flex flex-col">
                <div className="row items-left mb-6">
                    <NavLink to="/settings" >
                        <span className="top-0 left-0 p-2 mb-3 bg-gray-100 hover:bg-gray-200 rounded text-black cursor-pointer max-w-fit">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-2 inline"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                />
                            </svg>
                            Back
                        </span>
                    </NavLink>
                </div>
                <WarningBadge content={issueContent} visible={issueContent != null} onClose={() => setIssueContent(null)} />
                <div className="row items-left mb-6 max-w-2/3">
                    <h1 id="page-title" className="bold text-4xl inline">
                        Organization
                    </h1>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4 ">
                    <div>
                        <label htmlFor="orgName" className="block font-semibold">Organization Name:</label>
                        <input
                            type="text"
                            id="orgName"
                            className="w-full p-2 border rounded"
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                        />
                    </div>
                    <div>
                        <span className="block font-semibold">Seats Licensed:</span>
                        <span>{orgSeats}</span>
                    </div>
                </div>
                {orgSeats > 0 ?
                    <>
                        <div className="mb-4">
                            <h2 className="text-lg font-semibold mb-2">Add User:</h2>
                            <div className="flex">
                                <input
                                    type="email"
                                    placeholder="User email or emails (comma separated)"
                                    className="w-full p-2 border rounded mr-2"
                                    value={newUserEmail}
                                    onChange={(e) => setNewUserEmail(e.target.value)}
                                />
                                <button
                                    className="bg-blue-500 text-white aspect-square p-2 py-0 rounded"
                                    onClick={handleAddUser}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-lg font-semibold mb-2">Existing Users:</h2>
                            {users.map((user, index) => (
                                <div key={index} className="flex items-center justify-between mb-2">
                                    <div>
                                        <span className="mr-2">{user.email}</span>
                                        <span className={`px-2 py-1 text-white ${user.status === 'active' ? 'bg-green-500' : 'bg-yellow-500'} rounded`}>
                                            {user.status}
                                        </span>
                                    </div>

                                    <button
                                        onClick={() => handleRemoveUser(user.email)}
                                        className=" float-right right-0 aspect-square p-2 py-0 mr-2 bg-red-500 hover:bg-red-600 text-white rounded-md cursor-pointer"
                                    >
                                        x
                                    </button>
                                </div>
                            ))}
                        </div>

                    </> : <span className="my-2">Users cannot be added until payment is setup for at least 1 seat. <br />Save Organization name to proceed to checkout.</span>
                }
                {
                    orgId == null ?
                <div>
                    <button
                        className="bg-blue-500 text-white p-2 rounded"
                        onClick={handleCreateOrg}
                    >
                        Create Organization
                    </button>
                </div> : <></>
}
            </div>
        </PageLayout>
    );
};

export default OrganizationPage;
