import React, { useState } from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabs } from "./nav-bar-tabs";
import MobileNavbarPopup from "./mobile-nav-bar-popup";

export const NavBar = () => {

  const [popupOpen, setIsPopupOpen] = useState(false);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="nav-bar__container">
      <nav className="flex justify-between px-5 md:px-20 py-10 items-center bg-white border-b border-gray-100">
        <NavBarBrand />
        <div className="flex items-center hidden md:block">
          <ul className="flex items-center space-x-6">
            <NavBarTabs />
            <NavBarButtons />
          </ul>
        </div>
        <div className="md:hidden flex items-center float-right">
          <span className="pl-2 material-icons align-middle text-5xl cursor-pointer" onClick={() => setIsPopupOpen(true)}>
            menu
          </span>
        </div>
        <MobileNavbarPopup isOpen={popupOpen} onClose={closePopup} >
          <NavBarTabs />
          <NavBarButtons />
        </MobileNavbarPopup>
      </nav>
    </div>
  );
};
