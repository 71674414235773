import React from "react";


export const SettingsApiInfo = ({ apiInfo, newTokenCallback }) => {

    if (apiInfo === null) {
        return (<div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            Loading...
        </div>);
    }

    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            <div className="md:flex">
                <div className="p-8">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">API Information</div>
                    <div className="mt-4">
                        <label className="block text-gray-600 text-md font-medium">Client ID:</label>
                        <span className="bg-gray-100 mt-1 p-1 block w-full border-gray-300 shadow rounded-md">{apiInfo.client_id}</span>
                    </div>
                    <div className="mt-4">
                        <label className="block text-gray-600 text-md font-medium">API Key:</label>
                        <span className="bg-gray-100 p-1 mt-1 block w-full border-gray-300 rounded-md" > *************** {apiInfo.api_key_hint}</span>
                    </div>
                    <button className="mt-2 mx-auto block w-full md:w-auto text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm px-2 py-1 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        type="button" onClick={newTokenCallback}>
                        Get new API key
                    </button>
                </div>
            </div>
        </div>
    );
};