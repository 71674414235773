import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarTab = ({ path, label }) => {
  return (
    <NavLink to={path} end>
      <span className="font-semibold text-gray-700 text-center shadow md:shadow-none cursor-pointer rounded p-2 md:p-0 my-1.5 md:my-0 w-full md:w-fit block md:inline">
        {label}
      </span>
    </NavLink>

  );
};
