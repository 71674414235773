import React from "react";
import { PageLayout } from "../components/page-layout";

export const PrivacyPage = () => {

    return (
        <PageLayout>
            <div id="content" className="flex flex-col object-center sm:w-2/3 md:w-1/3 h-fit pb-20">
                <h1 className="mx-auto text-3xl text-blue-900 font-sans font-semibold tracking-wide mb-5">RUNABOUT</h1>
                <img src="imgs/RunaboutNew.png" alt="Runabout Logo" className="object-center mx-auto h-32 w-32 mb-6" />
                <h1 className="text-3xl mx-auto my-8">Privacy Policy</h1>

                <p className="mt-1.5">Runabout LLC operates the site runabout.dev to provide services including plugins for promgramming applications and APIs for enhancing developer experience.</p>

                <p className="mt-1.5">Runabout LLC is committed to protecting users' privacy and addressing potential privacy concerns. The following information has been posted to help you, the consumer, understand what private information we collect and how your information is used.</p>

                <h2 className="mt-3 text-2xl">Types of private information collected</h2>

                <h3 className="mt-3 text-xl">Personal information</h3>
                <ul className="list-disc ml-10">
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Payment/Billing Information</li>
                    <li>Billing address</li>
                </ul>

                <p className="mt-1.5">You can always choose to provide less information. However, omitting some of this personal information may limit your ability to access all of the benefits of our website and services. For example, we will not be able to process transactions or provide a subscription without payment information and a billing address.</p>

                <h3 className="mt-3 text-xl">Automatically collected information</h3>
                <p className="mt-1.5">We automatically collect data on how our users visit runabout.dev, such as your IP address, location, browser, browser language, operating system, and device identifiers.</p>

                <div className="bg-gray-100 p-2 mt-2">
                    <h3 className="text-xl">Information Collected in the Plugin</h3>
                    <p className="mt-1.5">Runabout, the plugin for Intellij IDEA, does not collect any user data.</p>

                    <h3 className="mt-3 text-xl">Information Collected in the API</h3>
                    <p className="mt-1.5">If you use our Runabout AI service through the plugin, we will collect basic usage data such as the number of requests you have made to the server, and the size of the data in those requests.</p>
                    <p className="mt-1.5 bg-blue-100">Runabout does not store or collect any of your code. If you use the catalog feature to store Scenarios or Instances, Runabout will only store the inputs that user created themselves or that were generated using AI.</p>
                </div>

                <h3 className="mt-3 text-xl">Social media information</h3>
                <p className="mt-1.5">If you've linked your Runabout account with a social media account, we also collect information social media networks give us. Depending on the social media network, this can include your username, full name, and email address. </p>

                <h2 className="mt-3 text-2xl">Policy on collecting sensitive personal information</h2>
                <p className="mt-1.5">Runabout does not gather any of your sensitive personal information, such as your social security number, driver's license number, race, ethnicity, religion, health metrics, political associations, or details on your criminal background.</p>

                <h2 className="mt-3 text-2xl">How the information collected is used</h2>
                <p className="mt-1.5">We only use your personal information to help you utilize everything Runabout has to offer. This includes:</p>
                <ul className="list-disc ml-10">
                    <li>Creating and maintaining an account with us</li>
                    <li>Completing your transactions</li>
                    <li>Fulfilling customer service requests</li>
                    <li>Communicating our sales and promotions</li>
                    <li>Contacting you regarding updates to our company policies</li>
                    <li>Making all of Runabout's services available to you in a convenient manner</li>
                    <li>Evaluating analytics to see how our customers engage with Runabout.dev</li>
                </ul>

                <h2 className="mt-3 text-2xl">Length of time personal information is stored</h2>
                <p className="mt-1.5">We may store your personal information for an extended period of time, depending on how we need to use the information. For example, we'll keep your email address and name on file as long as you have an account with us. In addition, we may need to keep your billing information on file for an extended period of time to maintain our internal transaction records.</p>

                <h2 className="mt-3 text-2xl">Who the information is shared with</h2>
                <p className="mt-1.5">We may share your information with third parties, but only those affiliates that Runabout LLC has fully vetted and created a partnership with.</p>
                <p className="mt-1.5">The only reason we will ever share your information with affiliates is for the following purposes:</p>
                <ul className="list-disc ml-10">
                    <li>Processing transactions</li>
                    <li>Providing excellent customer service</li>
                    <li>Issuing email and text message communications</li>
                </ul>

                <h2 className="mt-3 text-2xl">Information Security</h2>
                <p className="mt-1.5">We do our best to keep your private information secure and safe. Most of your personal information is encrypted and is therefore protected from being accessed by outside users. However, data leaks and malicious attacks do happen. Please understand that while we do our best to protect your data, providing your personal information is at your own risk.</p>

                <h2 className="mt-3 text-2xl">Ability to decline to provide personal information</h2>
                <p className="mt-1.5">You can, of course, decline to disclose your personal information to us. However, the failure to provide us with pertinent information may limit your ability to fully benefit from Runabout and all of its offerings. Remember, we only ask for personal information that can help us fully provide our services to our customers.</p>

                <h2 className="mt-3 text-2xl">Additional questions</h2>
                <p className="mt-1.5">If you have questions regarding this privacy policy, feel free to contact us at support@runabout.dev. A Runbaout LLC representative will respond to your privacy policy questions as soon as possible.</p>

                <h2 className="mt-3 text-2xl">Policy update</h2>
                <p className="mt-1.5">This privacy policy was last updated on 11/12/2023. If we adjust the privacy policy, we will email all account holders of this change and post the most recent update date here. Once posted, the new privacy policy shall be effective immediately.</p>

                <p className="mt-1.5 font-bold">Runabout LLC</p>
            </div>
        </PageLayout>
    );

};