import React, { useEffect } from "react";

export const WarningBadge = ({ content, visible, onClose }) => {
    useEffect(() => {
        let timer;

        if (visible) {
            // Set a timer to hide the badge after 5 seconds
            timer = setTimeout(() => {
                onClose();
            }, 15000);
        }

        // Clear the timer on component unmount or when the badge is manually closed
        return () => {
            clearTimeout(timer);
        };
    }, [visible, onClose]);

    return (
        visible && (
            <div className="bg-yellow-500 text-white p-2 m-2 flex rounded justify-between items-center block">
                <span>{content}</span>
                <button onClick={onClose} className="text-white">
                    <svg
                        className="w-4 h-4 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                </button>
            </div>
        )
    );
};
