import React from "react";
import { PageLayout } from "../components/page-layout";
import { SignupButton } from "../components/buttons/signup-button";

export const PricingPage = () => {

    return (
        <PageLayout>
            <div className="min-h-screen mt-0 flex justify-center">
                <div className="">
                    <div className="text-center font-semibold">
                        <h1 className="text-5xl">
                            <span className="text-blue-900 font-sans font-semibold tracking-wide mb-5">RUNABOUT </span>
                            <span>Plans</span>
                        </h1>
                        <p className="pt-4 pb-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
                            Choose a plan to get running.
                        </p>
                    </div>
                    <section className="py-6 dark:bg-gray-100 dark:text-gray-900">
                        <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                                <div className="flex flex-col col-span-2 items-center justify-center p-4 text-center rounded-md bg-gray-50">
                                    <span className="text-sm">Plugin + Account</span>
                                    <p className="text-5xl font-bold">Free</p>
                                    <ul className="text-left list-disc ml-10 my-10">
                                        <li>Run any scenario in your IDE</li>
                                        <li>Create scenarios by hand</li>
                                        <li>Save scenarios in the cloud</li>
                                        <li>Save the state of your debugger</li>
                                    </ul>
                                    <SignupButton htmlclassName={"px-8 py-1 mt-3 text-lg font-semibold border rounded border-gray-300 hover:bg-white"} />
                                </div>
                                <div className="flex flex-col col-span-3 items-center justify-center p-4 text-center rounded-md bg-blue-500 text-gray-50">
                                    <span className="text-sm ">Enterprise (replay debugging)</span>
                                    <p className="text-5xl font-semibold">Get on the waitlist</p>
                                    <ul className="font-semibold text-left list-disc ml-10 my-10">
                                        <li>Replay scenarios from production</li>
                                        <li>Shared scenarios across your organization</li>
                                        <li>Implementation support</li>
                                        <li>All features from the free version</li>
                                    </ul>
                                    <div className="w-5/6 py-1 flex mx-auto columns-10">
                                        <form action="https://stateracer.us21.list-manage.com/subscribe/post" method="POST">
                                            <input type="hidden" name="id" value="787eea1b5b" />
                                            <input type="hidden" name="u" value="c1e9ed01d6947d95091edc0ec" />
                                            <input id="MERGE0" name="MERGE0" className="px-4 py-1 mt-3 col-span-8 w-full text-lg text-gray-800 border rounded-md bg-white" type="text" placeholder="Your email" />
                                            <button type="submit" className="bg-blue-500 hover:bg-blue-800 border border-white text-white mt-3 ml-3 px-4 rounded-md" >Join</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </PageLayout>
    );

};