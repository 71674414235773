import React from "react";
import { PageLayout } from "../components/page-layout";

export const TermsPage = () => {

    return (
        <PageLayout>
            <div id="content" className="flex flex-col object-center sm:w-2/3 md:w-1/3 h-fit pb-20">
                <h1 className="mx-auto text-3xl text-blue-900 font-sans font-semibold tracking-wide mb-5">RUNABOUT</h1>
                <img src="imgs/RunaboutNew.png" alt="Runabout Logo" className="object-center mx-auto h-32 w-32 mb-6" />
                <h1 className="text-2xl mx-auto my-8">
                    Terms of Service
                </h1>

                <p className="mt-1.5">By using our website (runabout.dev), API, and IntelliJ plugin, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.</p>

                <h2 className="mt-3 text-xl">1. Acceptance of Terms</h2>
                <p className="mt-1.5">By accessing or using our IntelliJ plugin, API, or website, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.</p>

                <h2 className="mt-3 text-xl">2. Description of Services</h2>
                <p className="mt-1.5">We provide IntelliJ plugin and API developer tools for use by developers to enhance their development experience. You understand and agree that the services are provided "as is" and that Runabout LLC assumes no responsibility for the timeliness, deletion, misdelivery, or failure to store any user data or configurations.</p>

                <h2 className="mt-3 text-xl">3. User Responsibilities</h2>
                <p className="mt-1.5">You are responsible for maintaining the security of your account and for all activities that occur under your account. You agree not to share your account credentials or access to our services with others without proper authorization. You must notify us immediately of any unauthorized use of your account or any other breach of security.</p>

                <h2 className="mt-3 text-xl">4. Use of Our Services</h2>
                <p className="mt-1.5">You agree to use our services only for purposes that are legal, proper, and in accordance with these Terms of Service. You may not use our services to engage in any activity that infringes upon or violates the rights of others, or that is harmful, abusive, or otherwise objectionable.</p>

                <h2 className="mt-3 text-xl">5. Privacy Policy</h2>
                <p className="mt-1.5">Your use of our services is also governed by our Privacy Policy, which can be found at https://www.runabout.dev/privacy. By using our services, you consent to the collection and use of your personal information as outlined in the Privacy Policy.</p>

                <h2 className="mt-3 text-xl">6. Modifications to Services</h2>
                <p className="mt-1.5">We reserve the right to modify or discontinue, temporarily or permanently, any part of our services with or without notice. You agree that we shall not be liable to you or any third party for any modification, suspension, or discontinuance of our services.</p>

                <h2 className="mt-3 text-xl">7. Termination</h2>
                <p className="mt-1.5">We may terminate or suspend your access to our services at our sole discretion, with or without cause, and without notice. Upon termination, your right to use our services will immediately cease, any existing subscriptions will no longer be billed, and any data associated with your account may be deleted.</p>

                <h2 className="mt-3 text-xl">8. Disclaimer of Warranties</h2>
                <p className="mt-1.5">Our services are provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the quality, accuracy, or reliability of our services or the suitability of our services for your specific needs. Your use of our services is at your own risk.</p>

                <h2 className="mt-3 text-xl">9. Limitation of Liability</h2>
                <p className="mt-1.5">To the extent permitted by applicable law, Runabout LLC shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising out of or in connection with your use of our services.</p>

                <h2 className="mt-3 text-xl">10. Governing Law</h2>
                <p className="mt-1.5">These Terms of Service are governed by and construed in accordance with the laws of the United States. Any legal action or proceeding arising out of or related to these terms shall be brought exclusively in the courts of the United States, and you consent to the jurisdiction of such courts.</p>

                <h2 className="mt-3 text-xl">11. Contact Information</h2>
                <p className="mt-1.5">If you have any questions or concerns regarding these Terms of Service, please contact us at support@runabout.dev.</p>

                <p className="mt-1.5">Effective Date: 09/23/2023</p>

                <p className="mt-1.5 font-bold">Runabout LLC</p>

            </div>
        </PageLayout>
    );

};