import React from "react";

export const PageFooter = () => {

  return (
    <footer className="relative bg-gray-100 pt-4 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl fonat-semibold text-blue-800">Get running with Runabout today!</h4>
            <h5 className="text-lg mt-0 mb-1 mt-3 text-blueGray-600">
              Runabout currently is only available for Java projects in IntelliJ IDEA.
            </h5>
            <h5 className="text-lg mt-0 mb-1 text-blueGray-600">
              More IDEs & languages coming soon!
            </h5>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-2">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block text-blue-800 text-md font-semibold mb-2">Resources</span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="/privacy">Privacy</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="/terms">Terms & Conditions</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="/license">Plugin EULA</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="mailto:ethan@runabout.dev">Talk to the creator</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-3 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              Copyright © 2023 Runabout LLC by Ethan Bond.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
