import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { LandingPage } from "./pages/landing-page";
import { NotFoundPage } from "./pages/not-found-page";

import { TermsPage } from "./pages/terms-page";
import { PrivacyPage } from "./pages/privacy-page";
import { LicensePage } from "./pages/license-page";
import { PricingPage } from "./pages/pricing-page";
import { DashboardPage } from "./pages/dashboard-page";
import { SettingsPage } from "./pages/settings-page";
import { DocsPage } from "./pages/docs-page";
import OrganizationPage from "./pages/organization-page";
import ShareScenarioPage from "./pages/share-scenario-page";
import { PluginUI } from "./pluginui/plugin-ui-page";
import { KeysPage } from "./pages/keys-page";

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>

      <Route path="/" element={<LandingPage />} />

      <Route
        path="/dashboard"
        element={<AuthenticationGuard component={DashboardPage} />}
      />
      <Route
        path="/settings"
        element={<AuthenticationGuard component={SettingsPage} />}
      />

      <Route
        path="/organization"
        element={<AuthenticationGuard component={OrganizationPage} />}
      />

      <Route
        path="/share*"
        element={<AuthenticationGuard component={ShareScenarioPage} />}
      />

      <Route
        path="/keys"
        element={<AuthenticationGuard component={KeysPage} />}
      />

      <Route
        path="/docs"
        element={<DocsPage />}
      />
      
      <Route
        path="/pricing"
        element={<PricingPage />}
      />

      <Route
        path="/terms"
        element={<TermsPage />}
      />
      <Route
        path="/privacy"
        element={<PrivacyPage />}
      />
      <Route
        path="/license"
        element={<LicensePage />}
      />

      <Route path="/pluginui/panel/0.0.0" element={<PluginUI />} />

      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
