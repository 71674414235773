export const abbreviateMethod = (inputString) => {
    // Find the index of "#" in the input string
    const hashIndex = inputString.indexOf("#");

    // If "#" is not found or it's the first character or there's no "(" after it
    if (hashIndex === -1 || hashIndex === 0 || !inputString.includes("(")) {
        return inputString; // Return original string unmodified
    }

    // Find the index of the first "." before "#"
    const dotIndexBeforeHash = inputString.lastIndexOf(".", hashIndex);

    // Find the index of the first "(" after "#"
    const parenthesisIndexAfterHash = inputString.indexOf("(", hashIndex);

    // If there's no "." before "#" or no "(" after "#"
    if (dotIndexBeforeHash === -1 || parenthesisIndexAfterHash === -1) {
        return inputString; // Return original string unmodified
    }

    // Extract segments between "." before "#" and "(" after "#"
    const segment1 = inputString.slice(dotIndexBeforeHash + 1, hashIndex);
    const segment2 = inputString.slice(hashIndex + 1, parenthesisIndexAfterHash);

    // Concatenate segments with "."
    const result = `${segment1}.${segment2}`;

    return result;
}