import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <a className="font-semibold text-gray-700 text-center shadow md:shadow-none cursor-pointer rounded p-2 md:p-0 my-1.5 md:my-0 w-full md:w-fit block md:inline" target="_blank" rel="noreferrer" href="https://plugins.jetbrains.com/plugin/22630-runabout">Plugin</a>
      <a className="font-semibold text-gray-700 text-center shadow md:shadow-none cursor-pointer rounded p-2 md:p-0 my-1.5 md:my-0 w-full md:w-fit block md:inline" target="_blank" rel="noreferrer" href="https://github.com/Runabout-LLC/runabout-java">SDK</a>
      <a className="font-semibold text-gray-700 text-center shadow md:shadow-none cursor-pointer rounded p-2 md:p-0 my-1.5 md:my-0 w-full md:w-fit block md:inline" target="_blank" rel="noreferrer" href="https://docs.runabout.dev/docs/getting-started/">Docs</a>
      <NavBarTab path="/pricing" label="Pro" />
      {isAuthenticated && (
        <>
          <NavBarTab path="/dashboard" label="Dashboard" />
        </>
      )}
    </>
  );
};
