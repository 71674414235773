import React from "react"

export const SubscribeHeader = () => {

    return (<div className="bg-blue-100 p-2">
        <form className="max-w-screen-lg mx-auto flex justify-center"
            action="https://stateracer.us21.list-manage.com/subscribe/post" method="POST">
            <input type="hidden" name="id" value="787eea1b5b" />
            <input type="hidden" name="u" value="c1e9ed01d6947d95091edc0ec" />
            <div className="md:flex items-center text-center">
                <label className="mr-2 hidden md:block">Follow Runabout's development and get early access to new features:</label>
                <label className="mr-2 block md:hidden">Follow Runabout's development:</label>
                <div className="block md:inline justify-center flex mx-auto md:mx-0 items-center">
                    <input type="email" name="MERGE0" id="MERGE0"
                        className="border rounded px-2 py-1 w-1/2 md:w-auto focus:outline-none focus:border-blue-500" placeholder="Email" />
                    <button type="submit"
                        className="ml-2 bg-blue-100 border-black border text-black py-1 px-2 rounded hover:bg-white focus:outline-none focus:bg-blue-300">
                        Subscribe
                    </button>
                </div>
            </div>
        </form>
    </div>
    );
}