import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { formatDistance } from 'date-fns';
import { PluginUIModal } from './plugin-ui-modal';
import { abbreviateMethod } from '../utils';

export const PluginUI = () => { 

    const limit = 10;

    const [tab, settab] = useState('saved');
    const [search, setSearch] = useState('');
    const [error, setError] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [total, setTotal] = useState(0);

    const defaultCatchError = (error) => {
        if (error.message) {
            setError(error.message);
        } else {
            setError("An unkown error occurred. Please try again in a few minutes.");
        }
    };

    const reset = () => {
        setError(null);
        // setSearch('');
        setNodes([]);
        setTotal(0);
    };

    const getBearer = (consumer) => {
        try {
            window.runaboutPluginGetBearer((bearer) => {
                consumer(bearer);
            }, (error) => {
                defaultCatchError(error);
            });
        } catch (error) {
            defaultCatchError(error);
        }
    };

    const onPageChange = (e) => {
        reset();

        let query = '';

        if (tab === 'shared') {
            query = '?owned=false';
        } else if (tab === 'saved') {
            query = '?owned=true';
        }

        if (search) {
            query += '&name=' + search;
        }

        let page = e.page;

        let offset = page === 0 ? 0 : page * 10;
        fetch(query, limit, offset);
    };

    const onSelect = (e) => {
        // eslint-ignore-next-line
        try {
            window.runaboutPluginCallback(JSON.stringify({
                id: 'open',
                data: {
                       uuid: e.node.key.replace('-method', ''),
                }
            }));   
        } catch (error) {
            defaultCatchError(error);
        }
    };

    const fetch = (query, limit, offset) => {

        if (!query.includes('?')) {
            query += '?';
        } 

        if (query.charAt(query.length - 1) !== '?' || query.charAt(query.length - 1) !== '&') {
            query += '&';
        }

        query += 'limit=' + limit + '&offset=' + offset;

        getBearer((bearer) => {
            axios.get(window.runaboutPluginApiUrl + '/api/catalog/fetch/scenarios' + query, {
                headers: {
                    authorization: "Bearer " + bearer
                }
            }).then((response) => {
                if (response.data.data) {

                    setNodes(response.data.data.map((scenario) => {
                        let readableDate = formatDistance(new Date(scenario.created_on), new Date(), { addSuffix: true });
                        return {
                            key: scenario.uuid,
                            data: { type: 'Scenario', session: scenario.name, created: readableDate},
                            children: [{
                                key: scenario.uuid + '-method',
                                data: { type: '', session: abbreviateMethod(scenario.method_reference) }
                            }]
                        }
                    } ));

                    if (response.data.count) {
                        setTotal(response.data.count);
                    }
                }
            }).catch((error) => {
                defaultCatchError(error);
            });
        });
    }

    const refresh = () => {
        let query = '';

        if (tab === 'shared') {
            query = '?owned=false';
        } else if (tab === 'saved') {
            query = '?owned=true';
        }

        if (search) {
            query += '&name=' + search;
        }
        
        fetch(query, limit, 0);
    };

    let valid = (window.runaboutPluginApiUrl && window.runaboutPluginCallback && window.runaboutPluginGetBearer);

    useEffect(() => {
            if (valid) {
                refresh();
            }

    // eslint-disable-next-line
    }, [tab, valid]);

    const onClickReload = () => {
        window.location.reload();
    };

    // const onClickClipboard = () => {
    //     navigator.clipboard.readText()
    //     .then(text => {
    //         window.runaboutPluginCallback(JSON.stringify({ clipboard: text }));
    //     });
    // };


    // Error page / unsupported view from browser
    if (!valid) {
        return (
            <div className="mx-auto w-112 pt-16 h-screen text-center flex-col items-center bg-moonlight-dark">
                <img className="w-16 h-16 mx-auto" src="/imgs/RunaboutNew.png" alt="Runabout Logo" />
                <h1 className="text-moonlight-white text-2xl font-semibold mt-4">
                    This view is only supported in the 
                    <a className='underline text-moonlight-accent' href='https://plugins.jetbrains.com/plugin/22630-runabout' target='_blank' rel='noreferrer'>
                        &nbsp;Runabout Plugin.
                    </a>
                </h1>
                <h2 className="text-moonlight-white text-lg font-light mt-4 px-3">
                    If you are viewing this page from the runabout plugin, try reloading the UI by clicking the button below.
                </h2>
                <button onClick={onClickReload} className="text-moonlight-white p-2 mt-4 border border-moonlight-accent rounded bg-moonlight-dark">
                    Refresh
                </button>
            </div>
        );
    }

    return (
    <div className="mx-auto w-112 h-screen flex-col items-center bg-moonlight-dark px-3">
      {error && <PluginUIModal message={error} onClose={() => setError(null)} />}
      <div className="flex flex-col items-start pb-12">
        <div className='w-full flex flex-row mt-4'>
            <img className="w-16 h-16 mt-1 mr-4" src="/imgs/RunaboutNew.png" alt="Runabout Logo" />
            <div className='flex-grow'>
                <label htmlFor="project" className="text-sm text-moonlight-white mb-1 flex items-center">
                <span>Project</span>
                <svg className="ml-1 w-4 h-4" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-miterlimit="10" stroke-width="32" d="M256 80a176 176 0 1 0 176 176A176 176 0 0 0 256 80z"></path><path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="28" d="M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296"></path><circle cx="250" cy="348" r="20"></circle>
                </svg>
                </label>
                <select id="project" className="w-full inline font-light p-2 mb-2 border border-moonlight-accent rounded bg-moonlight-dark text-green-200 focus:outline-none">
                    <option>All Projects</option>
                    {/* <option>background-worker</option> */}
                </select>
            </div>
            <button onClick={refresh} className="justify-self-end text-moonlight-white p-2 ml-3 mt-auto mb-2 border border-moonlight-accent rounded bg-moonlight-dark">
                <svg className='w-5 h-5' stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"></path><path d="M21 3v5h-5"></path><path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"></path><path d="M8 16H3v5"></path>
                </svg>
            </button>
        </div>
        <hr className="my-2 w-full border border-moonlight-mid" />
        <div className="flex flex-col">
            <h2 className="text-md text-moonlight-white mb-3">
                Scenarios
            </h2>
            <div className="flex mb-3">
                <button onClick={() => settab('saved')} className={`px-2 py-1 font-light mx-1 rounded-t bg-moonlight-dark ${ tab === 'saved' ? 'border-x border-t' : 'border'} border-moonlight-accent text-moonlight-white`}>Saved</button>
                <button onClick={() => settab('shared')} className={`px-2 py-1 font-light mx-1 rounded-t bg-moonlight-dark ${ tab === 'shared' ? 'border-x border-t' : 'border'} border-moonlight-accent text-moonlight-white`}>Shared</button>
                <button onClick={() => {/* TODO */}} className={`px-2 py-1 font-light mx-1 flex rounded-t bg-moonlight-dark ${ tab === 'logged' ? 'border-x border-t' : 'border'} border-moonlight-accent text-moonlight-white`}>
                    <span>Logged</span>
                    <div className="inline ml-2 my-auto bg-moonlight-accent text-indigo-800 text-xs font-semibold w-4 h-4 rounded-full flex justify-center items-center">
                        <svg className='w-3 h-3' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"></path></svg>
                    </div>
                </button>
            </div>
        </div>
        <div className='bg-moonlight-dark text-moonlight-white font-normal py-2 px-4 border border-moonlight-accent rounded-t w-full'>
            <div>
                <input type="text" className="w-full bg-moonlight-dark text-moonlight-white font-normal my-1 mb-3 p-1 pl-2 border border-moonlight-accent rounded focus:outline-none" placeholder="Search" onChange={e => setSearch(e.target.value)} />
            </div>
            <span className='text-left float-left'>Name (expand for method)</span>
            <span className='text-right float-right'>Created</span>
        </div>
        <TreeTable className="bg-moonlight-dark w-full overflow-scroll text-moonlight-white font-extralight border-x border-b border-moonlight-accent px-2" 
            value={nodes} onRowClick={onSelect} 
            responsive scrollable 
            // loading={true} loaderIcon={<img src="loading.gif" alt="Loading..." />}
            paginator pagintorClassName={"text-red"} rows={limit} onPage={onPageChange}
            defaultSortOrder={1}
            totalRecords={total}
            lazy={true}>
            <Column field="session" bodyClassName="px-2 cursor-pointer truncate" expander></Column>
            {/* <Column body={actionTemplate} className="px-2"></Column> */}
            {/* <Column field="method" className="px-2 overflow-hidden"></Column> */}
            <Column field="created" bodyClassName="px-2 truncate w-max text-right" align="right"></Column>
        </TreeTable>
        {/* <button onClick={onClickClipboard} className='bg-moonlight-dark text-moonlight-white font-normal py-2 px-4 mt-3 border border-moonlight-accent rounded-sm w-full' >
            Launch from clipboard
        </button> */}
      </div>
    </div>
    );
};