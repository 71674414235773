import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fetchKeys, createKey, disableKey } from '../services/user.service';
import { PageLayout } from "../components/page-layout";

export const KeysPage = () => {

    const [keys, setKeys] = useState([]);
    const [newKeyName, setNewKeyName] = useState('');
    const [newKey, setNewKey] = useState(null);
    const [trigger, setTrigger] = useState(0);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const getKeys = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await fetchKeys(accessToken);

            console.log(data, error);

            if (data) {
                setKeys(data.data);
            }

            if (error) {
                window.location.href = "/settings";
            }
        };

        getKeys();
    }, [getAccessTokenSilently, trigger]);

    const makeKey = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await createKey(accessToken, newKeyName);

        if (data) {
            // TODO FLASH NEW KEY
            setNewKey(data.token);
            setTrigger(prev => prev + 1);
        }

        if (error) {
            // TODO - redirect if unauthorized
        }
    };

    const removeKey = async (id) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await disableKey(accessToken, id);

        if (data) {
            setTrigger(prev => prev + 1);
        }

        if (error) {
            // TODO - redirect if unauthorized
        }
    };

    const deleteButton = (rowData) => {
        return <button className="mt-2 mx-auto block w-full md:w-auto text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm px-2 py-1 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={() => {
            if (window.confirm(`Are you sure you want to delete this key [${rowData.name}]?`)) {
                removeKey(rowData.id);
            }
        }}>Delete</button>;
    };

    return (
        <PageLayout sidebar>
            <div id="content" className="flex flex-col object-center text-center sm:w-2/3 md:w-1/3 h-fit pb-20">
                <h1 id="page-title" className="bold text-4xl mb-6 mt-0">
                    Manage API Keys
                </h1>
                
            { newKey &&
                (<div className={"fixed inset-0 z-10 block"}>
                    <div className="modal-overlay bg-opacity-50 bg-black"></div>
                    <div className="modal-container bg-white w-2/3 overflow-hidden text-wrap mx-auto mt-24 rounded-lg shadow-lg">
                        <div className="overflow-hidden modal-content p-4">
                            <h2 className="text-xl font-semibold mb-2">New Api Key:</h2>
                            <p className="text-gray-600 text-sm mb-4">Copy this now and keep it safe. <br /> You will need to get a new API key if lost.</p>
                            <p className="p-4 bg-gray-200 text-wrap break-words rounded">{newKey.trim()}</p>
                            <button className="modal-close-button top-2 right-2 p-2 text-gray-600" onClick={() => setNewKey(null)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>)
            }
                <div>
                    <input type="text" placeholder="New key name" className='inline mx-4 border border-gray rounded p-2' onChange={e => setNewKeyName(e.target.value)} />
                    <button onClick={makeKey} className="inline bg-blue-500 hover:bg-blue-700 text-white cursor-pointer font-bold mt-3 py-2 px-4 rounded">
                        Create Key
                    </button>
                </div>
            
                <DataTable value={keys}>
                    <Column field="name" header="Name"></Column>
                    <Column field="hint" header="Secret"></Column>
                    <Column field="created_on" header="Created on"></Column>
                    <Column field="id" header="Delete" body={deleteButton}></Column>
                </DataTable>
            </div>
        </PageLayout>
    );
};