import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { fetchScenarioEvents } from "../services/user.service";
import { TreeTable } from "primereact/treetable";
import { Column } from 'primereact/column';
import { formatDistance } from "date-fns";
import { abbreviateMethod } from "../utils";

export const DashboardPage = () => {
  
  const limit = 15;

  const [scenarios, setScenarios] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  const refresh = async () => {
    const accessToken = await getAccessTokenSilently();
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await fetchScenarioEvents(accessToken, limit, offset);

    if (data && data.count) {
      setTotal(data.count);
    }

    setScenarios(data.data.map((scenario) => {
      return {
        key: scenario.id,
        data: { 
          method: abbreviateMethod(scenario.method_reference),
          created: formatDistance(new Date(scenario.datetime), new Date(), { addSuffix: true })
        }
      }
    }));
  };

  const onPageChange = (e) => {
    let offset = e.page === 0 ? 0 : e.page * limit;
    setOffset(offset);
    refresh();
  };

  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, offset]);

  return (
    <PageLayout sidebar>
      <div className="flex flex-col">
        <h1 id="page-title" className="bold text-4xl mb-6 mt-0">
          Scenarios
        </h1>
        <TreeTable value={scenarios}
            responsive scrollable 
            paginator pagintorClassName={"text-red"} rows={limit} onPage={onPageChange}
            defaultSortOrder={1}
            totalRecords={total}
            lazy={true}>
            <Column field="method"></Column>
            <Column field="created" className="px-2 truncate w-max text-right" align="right"></Column>
        </TreeTable>
      </div>
    </PageLayout>
  );
};
